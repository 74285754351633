<template>
  <div id="newEmployeePage" class="page">
    <div class="page__header sticky-page-header">
      <Toolbar :breadcrumbs="breadcrumbs" />
    </div>
    <div class="page__body">
      <Subheader :menu="menu" class="sticky-page-tab" />

      <General :data.sync="form" :loading="loading" @saveForm="handleSaveForm" />

      <ConfirmationPopup
        id="employee-create-leave-modal"
        title="Qeydə alınmamış məlumatlar"
        :confirmText="'Çıxış et'"
        :cancelText="'İmtina et'"
        v-if="confirmationDialog"
        :dialog="confirmationDialog"
        @confirm="handleLeaveConfirmation"
        @cancel="confirmationDialog = false"
      >
        Bu səhifədən çıxmaq istədiyinizə əminsiniz? Etdiyiniz qeydlər yadda saxlanılmayacaq
      </ConfirmationPopup>
    </div>
  </div>
</template>

<script>
import API from '@/services'
import generalEmployeeInfoMixin from '@/mixins/generalEmployeeInfo'
import bus from "../../../../event-bus";
import Toolbar from '../Shared/components/Toolbar/index.vue'
import General from '../Shared/components/Form/General/index.vue'
import Subheader from '../Shared/components/Subheader/index.vue'
import ConfirmationPopup from '@/shared/Common/ConfirmationPopup.vue'

export default {
  mixins: [generalEmployeeInfoMixin],
  components: {
    Toolbar,
    General,
    Subheader,
    ConfirmationPopup
  },
  data() {
    return {
      menu: [
        {
          text: 'Ümumi',
          disabled: false,
          link: '#'
        },
        {
          text: 'Müqavilə',
          disabled: true,
          link: '#'
        },
        {
          text: 'Əmr',
          disabled: true,
          link: '#'
        },
        {
          text: 'Staj/Əmək haqqı',
          disabled: true,
          link: '#'
        },
        {
          text: 'Əmək məzuniyyəti',
          disabled: true,
          link: '#'
        },
        {
          text: 'Sənədlər',
          disabled: true,
          link: '#'
        }
      ],
      breadcrumbs: [
        { title: 'Əməkdaşlar', url: '/employees' },
        { title: 'Yeni əməkdaş', url: '#' }
      ],
      loading: false,
      form: null
    }
  },
  methods: {
    handleSaveForm(form) {
      this.insertHREmployee(form)
    },
    insertHREmployee(data) {
      this.loading = true
      API.insertHREmployee(data)
        .then(id => {
          this.oldData = {
            ...this.form
          }
          this.$router.push({ name: 'EmployeesEditGeneral', params: { id } })
          bus.$emit('notificationMessage', {
            color: 'success',
            text: 'Əməkdaş əlavə edildi.'
          })
        })
        .catch(() => {
          bus.$emit('notificationMessage', {
            color: 'error',
            text: 'Əməkdaş əlavə etmək mümkün olmadı.'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
